import React from 'react';
import ReactDOM from 'react-dom';
import { DefaultTheme } from './Themes/Theme';
import App from './Components/App';
import reportWebVitals from './Components/reportWebVitals';

import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./Components/AppInsights";
import { Stack, ThemeProvider } from '@fluentui/react';

// Styles definition
const stackStyles = {
  root: {
    background: DefaultTheme.themeTertiary,
  },
};
const stackItemStyles = {
  root: {
    alignItems: 'center',
    background: DefaultTheme.themePrimary,
    color: DefaultTheme.white,
    display: 'flex',
    justifyContent: 'center',
  },
};

// Tokens definition
const stackTokens = {
  childrenGap: 5,
  padding: 10,
  margin: 10,
};

ReactDOM.render(
  <React.StrictMode>
    <AppInsightsContext.Provider value={reactPlugin}>
      <ThemeProvider theme={DefaultTheme}>
        <Stack horizontal styles={stackStyles} tokens={stackTokens}>
          <Stack.Item styles={stackItemStyles}>
            <App />
          </Stack.Item>
          <Stack.Item styles={stackItemStyles}>
          </Stack.Item>
        </Stack>
      </ThemeProvider>
    </AppInsightsContext.Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
