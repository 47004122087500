import logo from '../Resources/Images/logo.svg';
import './App.css';
import * as React from 'react';
import { Text } from '@fluentui/react/lib/Text';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from './AppInsights';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <Text as={'h1'}>Being in Touch</Text>
        <Text as={'h2'}>Wil jij bewuster leven met jouw leefruimte?</Text>
        <Text block>In deze tijden realiseren wij dat onze directe leefomgeving nu opeens veel meer word gebruikt. Om die reden wensen wij ons er optimaal in te kunnen voelen. Kantoor aan huis, iedereen lijkt er bijna wel aan te moeten. Maar hoe voel ik mij in mijn huis ook op mijn gemak gefocust te kunnen werken en hoe zorg ik dat na werktijd weer kan relaxen in de zelfde ruimtes.</Text>
        <Text block>Ik kan jouw begeleiden bewuster met je leefruimte om te gaan door je mee te nemen in de gedachte gang dat alles vanuit energie bestaat en dat jij je daarom in de ene ruimte fijn kan voelen en in de anderen niet zo fijn.</Text>
        <Text block>Wat voor jou?</Text>
        <Text block>Ultiem kunnen relaxen en gefocust werken vanuit je eigen huis?</Text>
        <Text block>Bewust met je spullen leven en goed voelen tussen jouw spullen?</Text>
        <Text block>Kunnen ademen en ruimte voelen zonder te ergeren aan van alles wat nog gedaan moet worden waar je dan weer stress van ervaart?</Text>
        <Text block>Een huis dat voor jullie allen goed voelt, al hebben jullie andere wensen?</Text>
        <Text block>Misschien wil je wel ontspullen maar weet je niet waar te beginnen of op te letten?</Text>
        <Text block>Makkelijk schoon te houden huis?</Text>
        <Text block>Wellicht wel binnen en buiten het gevoel van natuur om je heen wensen?</Text>
        <Text block>Hebben jullie je huis ingericht maar werkt het nog niet, mis je nog sfeer?</Text>
        <Text block>Of ben je net verhuisd en wens je advies hoe je leefruimtes interichten?</Text>
        <Text block>Als de bovenstaande vragen bij jouw interesse hebben gewekt om hiermee aan de slag te gaan heb ik een methodiek ontwikkeld waar mee je de rest van je leven je eigen heerlijk in je eigen leefruimtes kan voelen.</Text>
        <Text block>Ik bied coaching en begeleiding om je leefruimtes zo in te richten en op te ruimen dat jij je er prettiger in voelt en houd rekening met je huisgenoten en huisdieren want ook zij hebben wensen.</Text>
        <Text block>We starten met een vrijblijvend kennismakinggesprek en leg ik de methodiek “bewust omgaan met jouw leefruimte” toe. </Text>
        <Text block>Vervolgens plannen wij een tweede afspraak en aan de hand van een vragenlijst en rondleiding van de leefruimtes kan ik opmaken welk advies ik zal uitbrengen. </Text>
        <Text block>Vervolgens krijg je een adviesplan van mij en kun je het zelf toe te passen. Ook is het bespreekbaar dat wij het samen gaan toepassen.</Text>
      </header>
    </div>
  );
}

export default withAITracking(reactPlugin, App);
