// https://fluentuipr.z22.web.core.windows.net/heads/master/theming-designer/index.html
import { createTheme } from '@fluentui/react';

export const DefaultTheme = createTheme({
    palette: {
      themePrimary: '#2700d4',
      themeLighterAlt: '#f5f3fd',
      themeLighter: '#d8d0f8',
      themeLight: '#b7a9f2',
      themeTertiary: '#755ce5',
      themeSecondary: '#3d1ad9',
      themeDarkAlt: '#2300be',
      themeDark: '#1d00a1',
      themeDarker: '#160077',
      neutralLighterAlt: '#faf9f8',
      neutralLighter: '#f3f2f1',
      neutralLight: '#edebe9',
      neutralQuaternaryAlt: '#e1dfdd',
      neutralQuaternary: '#d0d0d0',
      neutralTertiaryAlt: '#c8c6c4',
      neutralTertiary: '#a19f9d',
      neutralSecondary: '#605e5c',
      neutralPrimaryAlt: '#3b3a39',
      neutralPrimary: '#323130',
      neutralDark: '#201f1e',
      black: '#000000',
      white: '#ffffff',
    },
    defaultFontStyle: {fontFamily: 'Monaco, Menlo, Consolas', fontWeight: 'regular'},
    
});